@use "sass:map";

// Required first stuff
@import "bootstrap/scss/functions";

// Variable overrides

$primary: #516c3a;

$font-family-base: "Montserrat";

@import "bootstrap/scss/variables";

// Map overrides

$spacers: map.merge($spacers, (6: $spacer * 4.5));

$container-max-widths: map.set(
  $container-max-widths,
  xl,
  map.get($container-max-widths, lg)
);

$container-max-widths: map.set(
  $container-max-widths,
  xxl,
  map.get($container-max-widths, lg)
);

$gutters: $spacers;

// Required first stuff
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Utility overrides

$utilities: map.merge($utilities, (
  "font-size": map.merge(map.get($utilities, "font-size"), (responsive: true)),
  "white-space": map.merge(map.get($utilities, "white-space"), (
    values: map.merge(
      map-get(map-get($utilities, "white-space"), "values"),
      (pre-line: pre-line),
    ),
  )),
));

// Root and reboot
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

// Layout, content, etc.
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";

// Components
@import "bootstrap/scss/buttons";

// Helpers
@import "bootstrap/scss/helpers/stretched-link";
@import "bootstrap/scss/helpers/text-truncation";

// Utilities
@import "bootstrap/scss/utilities/api";

// Custom functions and variables

// With this solution there may be some issues if horizontal padding is
// presented. It seems to solve these issues we need to use JS.
//
// Usage of `rfs-fluid-value`/`rfs-value` in `$sibling-container-font-size` is
// bad practice because we only rely on Bootstrap's RFS.
@function height-of-image-inside-container-depending-on-sibling-container(
  $container-padding-y,
  $sibling-container-padding-y,
  $sibling-container-font-size,
  $sibling-container-line-height,
) {
  @return calc(
    $sibling-container-font-size * $sibling-container-line-height
    + $sibling-container-padding-y * 2 - $container-padding-y * 2
  );
}

$spacer-between-4-and-5: calc((map.get($spacers, 4) + map.get($spacers, 5)) / 2);
$spacer-between-5-and-6: calc((map.get($spacers, 5) + map.get($spacers, 6)) / 2);

$social-network-link-icon-container-border-width: 5px;
$social-network-link-icon-container-border-width-sm: 4px;
$social-network-link-icon-container-border-width-lg: 6px;

$promotion-section-padding-y: $spacer-between-4-and-5;
$promotion-section-padding-x: map.get($spacers, 4);
$promotion-section-border-radius: 5rem;
$promotion-section-subsection-border-radius: calc(
  $promotion-section-border-radius -
  (($promotion-section-padding-y + $promotion-section-padding-x) / 2)
);

$promotion-link-with-text-padding-y: $spacer-between-4-and-5;
$promotion-link-with-text-padding-y-sm: map.get($spacers, 5);
$promotion-link-with-text-padding-y-md: map.get($spacers, 5);
$promotion-link-with-text-padding-y-lg: $spacer-between-5-and-6;
$promotion-link-with-text-font-size: map.get($display-font-sizes, 4);

// `@font-face`s

@font-face {
  font-family: "Montserrat";
  src: url(./fonts/Montserrat-SemiBold.woff2);
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  src: url(./fonts/Montserrat-Regular.woff2);
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: url(./fonts/Montserrat-Bold.woff2);
}

// Page

.page-bg {
  background-position: center;
  background-size: cover;
}

// Logo

.logo-img {
  margin-top: 1.5rem;
  margin-bottom: 2.25rem;

  @include media-breakpoint-up(md) {
    margin-top: 2.25rem;
    margin-bottom: 4rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 7rem;
    margin-bottom: 3rem;
  }
}

.logo-fest-img {
  $margin-y: calc((map.get($spacers, 4) + $spacer-between-4-and-5) / 2);

  margin-top: $margin-y;
  margin-bottom: $margin-y;
}

// Masthead

.masthead-heading {
  font-size: 4rem;
}

// `SocialNetworkLink` component

.social-network-link-icon-container-basic {
  padding-bottom: calc(100% - $social-network-link-icon-container-border-width-sm * 2);
  border-style: solid;
  border-width: $social-network-link-icon-container-border-width-sm;

  @include media-breakpoint-up(sm) {
    padding-bottom: calc(100% - $social-network-link-icon-container-border-width * 2);
    border-width: $social-network-link-icon-container-border-width;
  }
  @include media-breakpoint-up(md) {
    padding-bottom: calc(100% - $social-network-link-icon-container-border-width-lg * 2);
    border-width: $social-network-link-icon-container-border-width-lg;
  }
}

// `SectionDecorativeImage` component

.section-decorative-image {
  z-index: 100;
  background-size: contain;
}

// `Slider` components

.slider, .slider-navigation-button-container {
  margin-bottom: map.get($spacers, 5);

  @include media-breakpoint-up(lg) {
    margin-bottom: $spacer-between-5-and-6;
  }
}

.slider-navigation-button {
  width: 3rem;
  height: 3rem;

  @include media-breakpoint-up(sm) {
    width: 3.75rem;
    height: 3.75rem;
  }

  @include media-breakpoint-up(md) {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.slider-navigation-button-image {
  @extend .slider-navigation-button;
}

// `PromotionSection` component

.promotion-section {
  padding: $promotion-section-padding-y $promotion-section-padding-x;
  border-style: solid;
  border-radius: $promotion-section-border-radius;
}

.promotion-section-img {
  border-radius: $promotion-section-subsection-border-radius;
}

.promotion-section-img-heading {
  $margin-additional: .75rem;

  margin-bottom: $promotion-section-padding-y;

  @include media-breakpoint-up(md) {
    margin-top: $margin-additional;
    margin-bottom: calc($promotion-section-padding-y + $margin-additional / 2);
  }
}

// `PromotionLink` component

.promotion-link {
  border-radius: 2.25rem;

  @include media-breakpoint-up(md) {
    border-radius: 4rem;
  }

  @include media-breakpoint-up(lg) {
    border-radius: 3rem;
  }
}

.promotion-link-with-text {
  padding: $promotion-link-with-text-padding-y $spacer-between-4-and-5;
  @include font-size($promotion-link-with-text-font-size);

  @include media-breakpoint-up(sm) {
    padding: $promotion-link-with-text-padding-y-sm map.get($spacers, 5);
  }

  @include media-breakpoint-up(md) {
    padding: $promotion-link-with-text-padding-y-md map.get($spacers, 5);
  }

  @include media-breakpoint-up(lg) {
    padding: $promotion-link-with-text-padding-y-lg map.get($spacers, 5);
  }
}

// @todo Remove?
.promotion-link-label {
  width: 4rem;
  height: 4rem;

  @include media-breakpoint-up(sm) {
    width: 5rem;
    height: 5rem;
  }
  transform: translate(-50%, 50%);
}

// `Card` component

.custom-card-container {
  z-index: 10;
}

.custom-card {
  border-radius: 3rem;
}

.custom-card-btn {
  z-index: 110;
  border-radius: 2rem;
}

.custom-card-label {
  width: 5rem;
  height: 5rem;
}

// `BigLink` component

.big-link {
  border-radius: map.get($spacers, 4);

  @include media-breakpoint-up(lg) {
    padding:
      calc((map.get($spacers, 4) + $spacer-between-4-and-5) / 2)
      calc((map.get($spacers, 6) + $spacer-between-5-and-6) / 2)
      !important
    ;
    border-radius: $spacer-between-4-and-5;
  }
}

// Utilities

.border-light-blue {
  border-color: #00d5e3;
}

.gradient-to-right-from-red-to-purple {
  background: linear-gradient(to right, #ea3151, #9f1ff6);
}

.border-black {
  border-color: $black;
}

.gradient-to-bottom-from-gray-to-black {
  background: linear-gradient(to bottom, #4b4b4b, $black);
}

.gradient-to-bottom-golden {
  background: linear-gradient(to bottom, #ffd17b, #bc8754, #49290b);
}

.bg-red {
  background-color: #cc0a0a;
}

.bg-gray {
  background-color: #e6e6e6;
}

// Section decorative images

.section-decorative-image-container-bottom-left-of-fight-promotion-section,
.section-decorative-image-container-top-left-of-fest-promotion-section {
  width: 20%;

  @include media-breakpoint-up(sm) {
    width: 17.5%;
  }

  @include media-breakpoint-up(md) {
    width: 13%;
  }

  @include media-breakpoint-up(lg) {
    width: 11.5%;
  }
}

.section-decorative-image-bottom-left-of-fight-promotion-section {
  transform: translateY(70%);

  @include media-breakpoint-up(md) {
    transform: translateY(60%);
  }

  @include media-breakpoint-up(lg) {
    transform: translateY(35%);
  }
}

// Margins/paddings of sections and rows

.masthead {
  margin-bottom: 6rem;
}

.promotion-links-row {
  --bs-gutter-y: 5rem;

  @include media-breakpoint-up(md) {
    --bs-gutter-y: 7.5rem;
  }

  @include media-breakpoint-up(lg) {
    --bs-gutter-y: 10rem;
  }
}

.social-network-links-row {
  --bs-gutter-x: 1.5rem;

  @include media-breakpoint-up(sm) {
    --bs-gutter-x: 3rem;
  }

  @include media-breakpoint-up(lg) {
    --bs-gutter-x: 5rem;
  }
}

.first-slider-section {
  margin-bottom: 7rem;
}

.offers-section {
  margin-bottom: 4rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 6rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 7rem;
  }
}

main {
  margin-bottom: 6rem;
}

.fight-images-row {
  padding-top: $spacer-between-4-and-5;
}

.leading-events-section {
  margin-bottom: 3rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 4.5rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 6rem;
  }
}

.promotion-links-section,
.social-network-links-and-site-links-section {
  margin-bottom: 4.5rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 7rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 6rem;
  }
}

.social-network-links-and-site-links-section {
  margin-bottom: 3rem;
}

.cards-section {
  margin-bottom: 7rem;
}

.altai-palace-fight-section {
  margin-bottom: 6.5rem;
}

.altai-palace-group-section {
  padding-top: 10rem;
}

// Miscellaneous

.promotion-section-link {
  overflow: hidden;
  border-radius: $promotion-section-subsection-border-radius;
}

.altai-palace-group-link {
  @include font-size(map.get($display-font-sizes, 3));
  padding: $promotion-link-with-text-padding-y-lg map.get($spacers, 5);
  background-image: linear-gradient(to left, #402910 0%, #a88054 50%, #fccf7f 100%);
  border-radius: 3rem;
}
